/**
 * Custom validation functions for element-ui form elements.
 *
 * Uses async-validator under the hood.
 * See https://github.com/yiminghe/async-validator for options.
 */
import moment from 'moment'
import regex from '@/utils/regex'
import { isNumeric } from '@/utils'
import { isEmptyAddress } from '@/utils/rooof'

export const validators = {
  /**
   * Given a value, determine if it's a positive integer.
   *
   * @param {Object} rule - the validation rule
   * @param {*} value - value being validated
   * @param {Function} callback - callback function once validation is complete
   */
  positiveInteger: (rule, value, callback) => {
    if (!value) {
      return callback()
    }
    if (!Number.isInteger(value)) {
      return callback(new Error('Value must be an integer'))
    }
    if (value < 0) {
      return callback(new Error('Value cannot be negative'))
    }
    return callback()
  },
  /**
   * Given a value, determine if it's an integer.
   *
   * @param {Object} rule - the validation rule
   * @param {*} value - value being validated
   * @param {Function} callback - callback function once validation is complete
   */
  integer: (rule, value, callback) => {
    if (!value) {
      return callback()
    }
    if (!Number.isInteger(value)) {
      return callback(new Error('Value must be an integer'))
    }
    return callback()
  },
  /**
   * Given a value, determine if it's numeric.
   *
   * @param {Object} rule - the validation rule
   * @param {*} value - value being validated
   * @param {Function} callback - callback function once validation is complete
   */
  number: (rule, value, callback) => {
    if (!value) {
      return callback()
    }
    if (isNaN(value)) {
      return callback(new Error('Value must be a number'))
    }
    return callback()
  },
  /**
   * Given a numeric value, determine if it's zero or positive.
   *
   * @param {Object} rule - the validation rule
   * @param {*} value - value being validated
   * @param {Function} callback - callback function once validation is complete
   */
  positiveNumber: (rule, value, callback) => {
    if (!value) {
      return callback()
    }
    if (isNaN(value)) {
      return callback(new Error('Value must be a number'))
    }
    if (Math.sign(value) < 0) {
      return callback(new Error('Value cannot be negative'))
    }
    return callback()
  },
  /**
   * Given a value, determine if it's valid JSON.
   *
   * @param {Object} rule - the validation rule
   * @param {*} value - value being validated
   * @param {Function} callback - callback function once validation is complete
   */
  json: (rule, value, callback) => {
    if (!value) {
      return callback()
    }
    try {
      JSON.parse(value)
      return callback()
    } catch (err) {
      return callback(new Error('Invalid JSON'))
    }
  },
  /**
   * Given a value, determine if it's valid Regex.
   *
   * @param {Object} rule - the validation rule
   * @param {*} value - value being validated
   * @param {Function} callback - callback function once validation is complete
   */
  regex: (rule, value, callback) => {
    if (!value) {
      return callback()
    }
    try {
      RegExp(value)
      return callback()
    } catch (err) {
      return callback(new Error('Invalid Regex'))
    }
  },
  /**
   * Given a value, determine if it's a valid date.
   * Works with both strings and date objects.
   *
   * @param {Object} rule - the validation rule
   * @param {(String|Date)} value - value being validated
   * @param {Function} callback - callback function once validation is complete
   */
  date: (rule, value, callback) => {
    if (!value) {
      return callback()
    }
    if (moment(value).isValid()) {
      return callback()
    }
    return callback(new Error('Invalid date'))
  },
  /**
   * Given a value, determine if it contains valid email addresses.
   * Works with newline separated email strings.
   *
   * @param {Object} rule - the validation rule
   * @param {(String)} value - String being validated
   * @param {Function} callback - callback function once validation is complete
   */
  newlineEmails: (rule, value, callback) => {
    if (!value) {
      return callback()
    }

    // convert to an array of emails
    const emails = value.split('\n')
    const valid = emails.every(email => regex.email.test(email))

    if (valid) {
      return callback()
    }

    return callback(new Error('One or more email address(es) is invalid'))
  },
  /**
   * Given a string array, determine if it contains only unique values.
   *
   * @param {Object} rule - the validation rule
   * @param {Array} value - Array of values being validated
   * @param {Function} callback - callback function once validation is complete
   */
  uniqueValues: (rule, value, callback) => {
    if (!value) {
      return callback()
    }

    const copyArray = []

    for (const i in value) {
      if (value[i] !== '') {
        if (copyArray.includes(value[i])) {
          return callback(new Error('One or more values are not unique'))
        } else {
          copyArray.push(value[i])
        }
      }
    }

    return callback()
  },
  /**
   * Given a string, determine if it's a valid email domain (eg. @test.com)
   *
   * @param {Object} rule - the validation rule
   * @param {String} value - value being validated
   * @param {Function} callback - callback function once validation is complete
   */
  emailDomain: (rule, value, callback) => {
    if (!value) {
      return callback()
    }
    if (regex.domain.test(value)) {
      return callback()
    }
    return callback(new Error('Invalid domain'))
  },
  /**
   * Given a string, determine if it's a valid redirect link domain (eg. test.com)
   * @param {Object} rule = the validation rule
   * @param {String} value - value being validated
   * @param {Function} callback - callback function once validation is complete
   */
  RedirectLinkDomain: (rule, value, callback) => {
    if (!value) {
      return callback()
    }
    if (regex.redirect_domain.test(value)) {
      return callback()
    }
    return callback(new Error('Invalid domain (can not start with a special character) '))
  },
  /**
   * Given an address object, determine if it's non-empty.
   *
   * @param {Object} rule - the validation rule
   * @param {Object} value - address object
   * @param {Function} callback - callback function once validation is complete
   */
  emptyAddress: (rule, value, callback) => {
    if (!value) {
      return callback()
    }
    if (isEmptyAddress(value)) {
      return callback(new Error('Address is required'))
    }
    return callback()
  },
  /**
   * Given two dates, determine if the first (date1) falls after the second (date2).
   *
   * @param {String} date1
   * @param {String} date2
   * @param {Function} callback
   */
  billingDateValidator: (date1, date2, callback) => {
    if (!date1) {
      return callback()
    }

    if (Date.parse(date1) <= Date.parse(date2)) {
      return callback(new Error(`This field must be after ${date2}`))
    }

    return callback()
  },
  /**
   * Given a value, determine if it's defined.
   * Null and empty string are allowed.
   *
   * @param {Object} rule - the validation rule
   * @param {String} value - value being validated
   * @param {Function} callback - callback function once validation is complete
   */
  requireDefined: (rule, value, callback) => {
    if (value === undefined) {
      return callback(new Error('This field is required'))
    }
    return callback()
  },
  /**
   * Validate a password.
   *
   * @param {Object} rule - the validation rule
   * @param {String} value - value being validated
   * @param {Function} callback - callback function once validation is complete
   */
  password: (rule, value, callback) => {
    if (!value) {
      return callback()
    }
    if (isNumeric(value)) {
      return callback(new Error('Password cannot be entirely numeric'))
    }
    return callback()
  },
  /**
   * Validate tracking parameters
   *
   * Tracking Parameters must be one of the following:
   *   - URI ( must start with '/' )
   *   - Query String ( must start with '?' )
   *   - URI and query string
   *
   * @param {Object} rule - the validation rule
   * @param {String} trackingParameters - value being validated
   * @param {Function} callback - callback function once validation is complete
   */
  trackingParameterValidator: (rule, trackingParameters, callback) => {
    if (typeof trackingParameters !== 'string') {
      callback(new Error('Tracking parameters must be string'))
    }
    if (trackingParameters === '') {
      // empty values are accepted
      callback()
    }
    if (trackingParameters.substring(0, 1) !== '/' && trackingParameters.substring(0, 1) !== '?') {
      callback(new Error('Tracking parameters must begin with either "?" or "/"'))
    }
    callback()
  }
}
